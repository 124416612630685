import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import useProjectsListState from '@hellodarwin/core/lib/components/projects/hooks/use-projects-list-state';
import CompanyMainProjectTable from '@hellodarwin/core/lib/components/projects/main-project-table';
import {
  CreateProjectFormResponse,
  Project,
  ProjectFormValues,
} from '@hellodarwin/core/lib/features/entities/projects-entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import { selectCompanyById } from '../../../../features/api/slices/companies-slice';
import {
  addProjectTags,
  updateProjectTags,
} from '../../../../features/api/slices/new-tags-slice';
import {
  assignApplicationToProject,
  assignGrantToProject,
  createProject,
  fetchAllCompanyProjects,
  selectAllProjects,
  updateProject,
} from '../../../../features/api/slices/projects-slice';
import { useNewAdminApi } from '../../../../features/api/use-admin-api';
import { GinBlockComponentProps } from '../../../gins/gin-single/gin-block';
import ProjectFormDrawer from './project-form/drawer';
import ProjectFormModal from './project-form/modal';
import AddNewProjectItemModal from './subitems-forms/add-new-project-item-modal';
import { handleSaveApplicationFunction } from './types';

const CompanyProjectsBlock: React.FC<GinBlockComponentProps> = ({
  isEditing,
  handleEditing,
  entityId,
  section,
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();

  const api = useNewAdminApi();
  const dispatch = useAppDispatch();

  const projects = useAppSelector(selectAllProjects);
  const company = useAppSelector((state) => selectCompanyById(state, entityId));

  useEffect(() => {
    dispatch(
      fetchAllCompanyProjects({
        api,
        companyId: entityId,
        locale: selectedLocale,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  const projectsListState = useProjectsListState({
    defaultState: 'editable',
    projects,
    grantsPath: '/gin',
    applicationsPath: '/programs',
  });

  const {
    activeProject,
    activeGrant,
    activeModal,
    activeParentProject,
    handleAddProject,
    handleCloseModal,
  } = projectsListState;

  useEffect(() => {
    if (isEditing) {
      handleAddProject();
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const handleClose = () => {
    handleCloseModal();
    if (isEditing) handleEditing();
  };

  const handleSaveProject = async (values: ProjectFormValues) => {
    const started_date = values.timeline?.[0]?.toDate();
    const end_date = values.timeline?.[1]?.toDate();
    if (!!activeProject.project_id.length) {
      const data: Project = {
        ...activeProject,
        ...values,
        started_date,
        end_date,
      };

      const response = await dispatch(updateProject({ api, data }));
      if (response.meta.requestStatus === 'fulfilled') {
        await dispatch(
          updateProjectTags({
            api,
            projectId: activeProject.project_id,
            tags: values.tags.map((tag) => tag.tag_id),
          }),
        );
      }
    } else {
      const data: CreateProjectFormResponse = {
        ...values,
        started_date,
        end_date,
        company_id: entityId,
      };

      if (activeParentProject) {
        data['parent_project'] = activeParentProject.project_id;
      }

      const response = await dispatch(createProject({ api, data }));
      if (
        response.meta.requestStatus === 'fulfilled' &&
        (response.payload as Project).project_id
      )
        await dispatch(
          addProjectTags({
            api,
            projectId: (response.payload as Project)?.project_id ?? '',
            tags: values.tags.map((tag) => tag.tag_id),
          }),
        );
    }
    handleClose();
  };

  const handleSaveGrant = async (grantId: string) => {
    return await dispatch(
      assignGrantToProject({
        api,
        grantId,
        projectId: activeProject.project_id,
      }),
    ).then(({ meta: { requestStatus } }) => {
      switch (requestStatus) {
        case 'fulfilled':
          return true;

        case 'rejected':
          return false;
      }
    });
  };

  const handleSaveApplication: handleSaveApplicationFunction = async ({
    applicationId,
    projectId,
    grantId,
  }) => {
    if (!activeGrant && !projectId) return false;
    projectId = projectId ?? activeGrant?.project_id ?? '';
    if (!!grantId) {
      dispatch(assignGrantToProject({ api, grantId, projectId }));
    }
    return await dispatch(
      assignApplicationToProject({
        api,
        applicationId,
        projectId,
      }),
    ).then(({ meta: { requestStatus } }) => {
      switch (requestStatus) {
        case 'fulfilled':
          return true;

        case 'rejected':
          return false;
      }
    });
  };

  return (
    <>
      {!!projects.length ? (
        <Div flex="column" gap={16}>
          {projects.map((p, i) => (
            <div
              key={p.project_id}
              style={{
                padding: 24,
                borderRadius: 4,
                border: `1px solid ${theme.colors.grey_4}`,
              }}
            >
              <CompanyMainProjectTable
                projectsListState={projectsListState}
                project={p}
              />
            </div>
          ))}
        </Div>
      ) : (
        <Empty
          description={
            <Typography elementTheme="subtitle2" color={theme.colors.grey_2}>
              {t(`grant_single|emptyField`, {
                fieldName: t(`singleCompanyPage|${section}`),
              })}
            </Typography>
          }
        />
      )}
      <ProjectFormDrawer
        open={activeModal === 'edit-project'}
        handleCancel={handleClose}
        project={activeProject}
        parentProject={activeParentProject}
        handleSaveProject={handleSaveProject}
      />
      <AddNewProjectItemModal
        handleCancel={handleClose}
        open={['add-new-project-item', 'add-new-grant-item'].includes(
          activeModal ?? '',
        )}
        defaultState={
          activeModal === 'add-new-grant-item' ? 'grant' : 'application'
        }
        handleSaveGrant={handleSaveGrant}
        company={company}
        handleSaveApplication={handleSaveApplication}
        parentGrant={activeGrant}
        project={activeProject}
      />
      <ProjectFormModal
        open={activeModal === 'add-project'}
        handleCancel={handleClose}
        project={activeProject}
        parentProject={activeParentProject}
        handleSaveProject={handleSaveProject}
      />
    </>
  );
};

export default CompanyProjectsBlock;
